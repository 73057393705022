.countdown {
  display: flex;
  align-items: center;
  margin: auto;
}

.countdown .number {
  background-color: #27245b;
  padding: 10px 20px;
  margin: 0 3px;
  font-size: 50px;
  font-family: "JetBrains Mono", monospace;
  font-weight: 500;
  color: #fff;
}

.countdown h3 {
  font-size: 15px;
  font-weight: bold;
  color: #27245b;
  margin-top: 20px;
  max-width: 200px;
  text-align: center;
}

.countdown > div {
  max-width: 300px;
  margin-left: 60px;
}

.countdown p {
  color: #27245b;
}

@media (max-width: 768px) {
  .countdown {
    flex-direction: column;
  }

  .countdown p {
    margin-left: 0;
    margin-top: 20px;
    text-align: center;
    font-size: 14px;
  }
}
