.auditListHeader {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.auditListHeader p {
  font-size: 12px;
  color: #8b8b8b;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.auditStatus {
  display: flex;
  align-items: center;
  margin-left: 30px;
  flex: 0.5;
}

.date {
  flex: 0.5;
  margin-left: 15px;
}

.totalRecovered {
  flex: 1;
  margin-left: 15px;
  margin-right: 120px;
}

@media (max-width: 768px) {
  .auditListHeader {
    display: none;
  }
}
