.modalWrapper {
  align-items: center;
  justify-content: center;
  z-index: 200;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
}

.modalWrapper.open {
  display: flex;
}

.modal {
  width: 100%;
  max-width: 500px;
  border-radius: 5px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.5);
  padding: 0;
  background-color: #fff;
  z-index: 200;
}

.scrim {
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}
