.content {
  background-color: #fff;
  border: 1px solid #dedede;
  margin: 1rem;
}

@media (max-width: 768px) {
  .content {
    border: none;
    margin: 0;
  }
}
