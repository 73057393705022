.container {
  width: 100%;
  max-width: 1140px;
  margin: 60px auto;
}

.admin {
  margin: 0;
  padding: 24px;
  height: 100%;
  max-width: none;
}

@media (max-width: 768px) {
  .container {
    margin: 0;
  }
}
