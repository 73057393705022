.cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 200px;
  grid-gap: 15px;
  padding: 15px;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f7f7f7;
  text-align: center;
  padding: 15px;
  cursor: pointer;
}

.card:hover {
  background-color: rgba(247, 247, 247, 0.8);
}

.card:active {
  background-color: #f7f7f7;
}

.card img {
  width: 70px;
  height: 70px;
  margin-bottom: 10px;
}

.card h3 {
  font-size: 15px;
  font-weight: bold;
  color: #27245b;
}

.card p {
  font-size: 12px;
  color: #696877;
  line-height: 14px;
  max-width: 188px;
  margin-top: 10px;
}

.nextAudit {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px;
  margin: 15px 15px 0 15px;
  border: 2px solid #f7f7f7;
}

@media (max-width: 768px) {
  .cards {
    grid-template-columns: 1fr;
    grid-auto-rows: 200px;
  }
}
