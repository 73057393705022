.auditDetailCard {
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  border-bottom: 3px solid #27245b;
}

.auditDetailCard.centered {
  align-items: center;
  justify-content: center;
}

.auditDetailCard h1 {
  font-size: 40px;
  font-weight: 500;
  color: #27245b;
}

.auditDetailCard p {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.auditDetailCard > p {
  color: #696877;
}

@media (max-width: 768px) {
  .auditDetailCard h1 {
    font-size: 26px;
  }
}
