.auditReportTable thead {
  position: sticky;
  top: 0;
  background-color: white;
}

.auditReportTable thead th {
  text-align: left;
  font-size: 12px;
  letter-spacing: 1px;
  color: #8b8b8b;
  text-transform: uppercase;
  font-weight: normal;
  padding: 0 15px 15px 15px;
  align-items: flex-end;
}

.auditReportTable tbody tr {
  background-color: #f7f7f7;
  margin-bottom: 5px;
}

.auditReportTable tbody tr td {
  padding: 15px;
  font-size: 14px;
  font-weight: 400;
  color: #696877;
  text-overflow: ellipsis;
}

.auditReportTable tbody tr:last-child {
  margin-bottom: 0;
}

.auditReportTable tbody tr td a {
  color: #635bf5;
}
