@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@500&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body,
#root {
  height: calc(100vh - 50px);
}

body {
  background-color: #f7f7f7;
}
