.accountSettings {
  flex: 0.45;
  margin-right: 10px;
}

.auditsAndInvoices {
  flex: 0.55;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .accountSettings {
    margin-right: 0;
    margin-bottom: 0.5rem;
  }
  .auditsAndInvoices {
    margin-left: 0;
  }
}
