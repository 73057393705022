.auditDetailCards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 210px;
  grid-gap: 15px;
}

@media (max-width: 768px) {
  .auditDetailCards {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 210px;
  }
}
