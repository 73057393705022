.auditListItem {
  display: flex;
  align-items: center;
  height: 57px;
  background-color: #f7f7f7;
  margin-bottom: 15px;
}

.auditListItem.last {
  margin-bottom: 0;
}

.auditListItem p {
  font-size: 14px;
  color: #696877;
}

.auditListItem .number {
  width: 57px;
  height: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ececec;
}

.auditListItem .number p {
  color: #27245b;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1.45px;
}

.auditStatus {
  display: flex;
  align-items: center;
  margin-left: 30px;
  flex: 0.5;
}

.auditStatus p {
  margin: 0 0 0 10px;
  color: #27245b;
  font-weight: 500;
}

.auditStatus.done p {
  color: #43d365;
}

.date {
  flex: 0.5;
  margin-left: 15px;
}

.totalRecovered {
  flex: 1;
  margin-left: 15px;
}

.totalRecovered p {
  font-size: 16px;
  color: #27245b;
}

.viewDetailsButton {
  padding-right: 10px;
  margin-left: 15px;
}

.viewDetailsButton.inProgress {
  display: none;
}

@media (max-width: 768px) {
  .auditStatus p {
    display: none;
  }
}
