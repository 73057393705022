.formGroup {
  margin-bottom: 15px;
}

.formGroup:last-of-type {
  margin-bottom: 0;
}

.formGroup label {
  font-weight: 500;
  color: #27245b;
  font-size: 14px;
  margin-bottom: 9px;
  display: block;
}

.formGroup:not(.basic) input,
.formGroup:not(.basic) textarea {
  background-color: #f7f7f7;
  padding: 11px;
  display: block;
  width: 100%;
}

.formGroup:not(.basic) textarea {
  min-height: 140px;
}

.formGroup:not(.basic) input {
  height: 43px;
}

.formGroup input:disabled {
  opacity: 0.5;
}
