.successMessage {
  background-color: #43d365;
  padding: 15px;
  display: flex;
}

.successMessage p {
  flex: 1;
  margin: 0 0 0 15px;
  color: #fff;
  font-weight: 6001;
}
