.formLabel {
  font-weight: 500;
  color: #27245b;
  font-size: 14px;
  margin-bottom: 9px;
  margin-top: 15px;
  display: block;
}

.grayImage {
  filter: grayscale(100%) invert(45%) !important;
}
