.button {
  height: 43px;
  padding: 13px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #696877;
  color: #fff;
  border-radius: 3px;
  font-size: 13px;
  font-weight: 500;
  outline: none;
}

.button:disabled {
  opacity: 0.8;
  cursor: default;
}

.button.active {
  background-color: #dedede !important;
}

.button:not(:disabled):hover {
  background-color: rgba(105, 104, 119, 0.3);
}

.button:not(:disabled):active {
  background-color: rgba(105, 104, 119, 0.5);
  outline: none;
}

.button.fill {
  width: 100%;
}
.button.icon {
  padding: 9px;
}

.button.clear {
  background: none;
}

.button.primary {
  background-color: #27245b;
  color: #5bf67f;
}
.button.secondary {
  background-color: #dedede;
  color: #696877;
}

.button:not(:disabled).primary:hover {
  background-color: rgba(39, 36, 91, 0.8);
}

.button:not(:disabled).primary:active {
  background-color: #27245b;
}

.button.small {
  padding: 10px 10px;
  height: 2.25rem;
}

.button.xsmall {
  padding: 5px;
  height: 2rem;
}
