.loginContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.loginFormSubsection {
  border-top: 1px solid #dedede;
}

.loginForm,
.loginFormSuccess {
  max-width: 390px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #dedede;
  overflow-y: auto;
}

.loginFormSuccess {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
}

.loginFormSuccess img {
  width: 70px;
  height: 70px;
  margin-bottom: 10px;
}

.content h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;

  color: #27245b;
}

.loginFormSuccess h3 {
  font-size: 15px;
  font-weight: bold;
  color: #27245b;
}

.loginFormSuccess p {
  font-size: 13px;
  color: #696877;
  max-width: 240px;
  margin-top: 10px;
  text-align: center;
}

.backButton {
  position: fixed;
  top: 45px;
  left: 45px;
  display: flex;
  align-items: center;
}

.backButton span {
  margin-left: 15px;
  color: #27245b;
  font-weight: bold;
}

.header {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #dedede;
}

.content {
  padding: 15px;
}

.content p {
  color: #696877;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 20px;
}

.content p.lastParagraph {
  margin-bottom: 0px;
}

.content_break {
  border-bottom: 1px solid #dedede;
}

.input {
  padding: 18px;
  height: 57px;
  background-color: #f7f7f7;
  width: 100%;
  margin-bottom: 15px;
}

.input:disabled {
  opacity: 0.5;
}

.input:last-of-type {
  margin-bottom: 0;
}

.inputHelper {
  padding: 5px 0;
  text-align: right;
}

.inputHelper a {
  color: #696877;
  font-size: 12px;
  text-decoration: underline;
}

.formHelper {
  padding: 15px 0;
  text-align: center;
}

.formHelper a {
  color: #696877;
  font-size: 12px;
  font-weight: 500;
}

.button {
  margin-top: 20px;
  height: 57px;
  width: 100%;
  padding: 18px;
  text-align: center;
  background-color: #27245b;
  color: #5bf67f;
  font-weight: 600;
  outline: none;
}

.button:disabled {
  background-color: rgba(39, 36, 91, 0.8);
}

.button:focus {
  outline: none;
}

.button:not(:disabled):hover {
  background-color: rgba(39, 36, 91, 0.9);
}

.button:active {
  background-color: #27245b;
  outline: none;
}
