.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  background-color: #fff;
  border-bottom: 2px solid #dedede;
  padding: 0 15px;
  position: sticky;
  top: 0;
  z-index: 20;
}

.navbar ul {
  display: flex;
  align-items: center;
}

.navbar nav ul li {
  display: inline-block;
  margin: 0 12px;
}

.navbar nav ul li:first-child {
  margin-left: 0;
}

.navbar nav ul li:last-child {
  margin-right: 0;
}

.navbar a {
  font-family: "JetBrains Mono", monospace;
  font-size: 14px;
  color: #27245b;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  font-weight: 500;
}

.navbar a:hover {
  color: rgba(39, 36, 91, 0.8);
}

.navbar a:global(.active) {
  color: #5bf67f;
}

.brand {
  height: 40px;
  width: auto;
}

.navbar ul li svg {
  display: none;
}

@media (max-width: 768px) {
  .navbar ul li svg {
    display: block;
  }
  .navbar ul li span {
    display: none;
  }
}
