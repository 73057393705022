.toolbar {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 48px;
  border-bottom: 1px solid #dedede;
  padding: 0 15px;
}

.toolbar.withButtons {
  justify-content: space-between;
}

.toolbar h1 {
  font-family: "JetBrains Mono", monospace;
  font-size: 14px;
  color: #27245b;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  font-weight: 500;
}
