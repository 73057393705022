.reportTableWrapper {
  overflow: auto;
  position: relative;
  max-height: 300px;
  -webkit-overflow-scrolling: touch;
}

/* width */
.reportTableWrapper::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
.reportTableWrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 8px;
}

/* Handle */
.reportTableWrapper::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

/* Handle on hover */
.reportTableWrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}
